var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.fullscreenLoading,
          expression: "fullscreenLoading",
          modifiers: { fullscreen: true, lock: true }
        }
      ]
    },
    [
      _c(
        "div",
        { staticClass: "upload" },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-upload",
                    {
                      attrs: {
                        action: _vm.path + "/fileUploadAndDownload/upload",
                        "before-upload": _vm.checkFile,
                        headers: { "x-token": _vm.token },
                        "on-error": _vm.uploadError,
                        "on-success": _vm.uploadSuccess,
                        "show-file-list": false
                      }
                    },
                    [
                      _c(
                        "el-button",
                        { attrs: { size: "small", type: "primary" } },
                        [_vm._v("点击上传")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "el-upload__tip",
                          attrs: { slot: "tip" },
                          slot: "tip"
                        },
                        [_vm._v(" 只能上传jpg/png文件，且不超过500kb ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _vm._v(" 带压缩的上传, (512(k)为压缩限制) "),
                  _c("upload-image", {
                    attrs: { fileSize: 512, maxWH: 1080 },
                    model: {
                      value: _vm.imageUrl,
                      callback: function($$v) {
                        _vm.imageUrl = $$v
                      },
                      expression: "imageUrl"
                    }
                  }),
                  _vm._v(" 已上传文件 " + _vm._s(_vm.imageUrl) + " ")
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-table",
            { attrs: { data: _vm.tableData, border: "", stripe: "" } },
            [
              _c("el-table-column", {
                attrs: { label: "预览", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("CustomPic", {
                          attrs: { picType: "file", picSrc: scope.row.url }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "日期", prop: "UpdatedAt", width: "180" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm._f("formatDate")(scope.row.UpdatedAt))
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "文件名", prop: "name", width: "180" }
              }),
              _c("el-table-column", {
                attrs: { label: "链接", prop: "url", "min-width": "300" }
              }),
              _c("el-table-column", {
                attrs: { label: "标签", prop: "tag", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-tag",
                          {
                            attrs: {
                              type:
                                scope.row.tag === "jpg" ? "primary" : "success",
                              "disable-transitions": ""
                            }
                          },
                          [_vm._v(_vm._s(scope.row.tag))]
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "160" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.downloadFile(scope.row)
                              }
                            }
                          },
                          [_vm._v("下载")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.deleteFile(scope.row)
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c("el-pagination", {
            style: { float: "right", padding: "20px" },
            attrs: {
              "current-page": _vm.page,
              "page-size": _vm.pageSize,
              "page-sizes": [10, 30, 50, 100],
              total: _vm.total,
              layout: "total, sizes, prev, pager, next, jumper"
            },
            on: {
              "current-change": _vm.handleCurrentChange,
              "size-change": _vm.handleSizeChange
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }